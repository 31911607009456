<template>
  <div>
    <Depositwithdraw></Depositwithdraw>
  </div>
</template>

<script>
// @ is an alias to /src
import Depositwithdraw from '@/components/Depositwithdraw.vue'

export default {
  name: 'DepositWithdraw',
  components: {
    Depositwithdraw,
  }
}
</script>
