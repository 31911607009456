<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Asidemenu></Asidemenu>
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header></Header>
          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Post-->
            <div class="post d-flex flex-column-fluid" id="kt_post">
              <!--begin::Container-->
              <div id="kt_content_container" class="container-xxl">
                <!--begin::Navbar-->
                <div class="card mb-5 mb-xl-10">
                  <div class="card-body pt-9 pb-0">
                    <!--begin::Details-->
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Stats-->
                        <div class="d-flex flex-wrap flex-stack">
                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column flex-grow-1 pe-8">
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap">
                              <!--begin::Stat-->
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="supbalance" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{ this.$t('dashboard.supplybalance')}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <div class="
                                  border border-gray-300 border-dashed
                                  rounded
                                  min-w-125px
                                  py-3
                                  px-4
                                  me-4
                                  mb-3
                                ">
                                <!--begin::Number-->
                                <div class="d-flex align-items-center">
                                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                  <span class="svg-icon svg-icon-3 svg-icon-success">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                        transform="rotate(90 13 6)" fill="black" />
                                      <path
                                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                        fill="black" />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <div class="fs-2 fw-bolder w-140px" data-kt-countup="true"
                                    :data-kt-countup-value="`${borrowbalanceUSDT}`" data-kt-countup-prefix="$"
                                    data-kt-countup-decimal-places="2">
                                    0
                                  </div>
                                </div>
                                <!--end::Number-->
                                <!--begin::Label-->
                                <div class="fw-bold fs-6 text-gray-400">
                                  {{$t("dashboard.borrowbalance")}}
                                </div>
                                <!--end::Label-->
                              </div>
                              <!--end::Stat-->
                            </div>
                            <!--end::Stats-->
                          </div>
                          <!--end::Wrapper-->
                          <!--begin::Progress-->
                          <div class="
                              d-flex
                              align-items-center
                              w-200px w-sm-300px
                              flex-column
                              mt-3
                            ">
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{this.$t('dashboard.availablecredit')}}</span>
                              <span class="fw-bolder fs-6 fw-bolder" data-kt-countup="true"
                                :data-kt-countup-value="borrowlimit" data-kt-countup-prefix="$"
                                data-kt-countup-decimal-places="2">0</span>
                            </div>
                            <div class="
                                d-flex
                                justify-content-between
                                w-100
                                mt-auto
                                mb-2
                              ">
                              <span class="fw-bold fs-6 text-gray-400">{{$t("dashboard.borrowlimit")}}</span>
                              <span class="fw-bolder fs-6">{{ borrowbalanceyuzde.toFixed(2) }}%</span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                              <div :class="`bg-${isSuccessBorrowUst} rounded h-5px`" role="progressbar"
                                :style="`width: ${borrowbalanceyuzde}%;`" aria-valuenow="50" aria-valuemin="0"
                                aria-valuemax="100"></div>
                            </div>
                          </div>
                          <!--end::Progress-->
                        </div>
                        <!--end::Stats-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Details-->
                  </div>
                </div>
                <!--end::Navbar-->
                <!--begin::Row-->
                <div class="row gy-5 g-xl-8">
                  <!--depositwithdraw tablo start-->
                  <div class="col-xl-12">
                    <!--begin::Tables Widget 5-->
                    <div class="card card-xl-stretch mb-5 mb-xl-10">
                      <!--begin::Header-->
                      <div class="card-header border-0 pt-5">
                        <div class="card-toolbar">
                          <ul class="nav">
                            <li class="nav-item">
                              <a @click="depwithTab = 'deposit'" class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  active
                                  fw-bolder
                                  px-4
                                  me-1
                                " data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_1_sag">{{$t("deposit.deposithistory")}}</a>
                            </li>
                            <li class="nav-item">
                              <a @click="depwithTab = 'withdraw'" class="
                                  nav-link
                                  btn
                                  btn-sm
                                  btn-color-muted
                                  btn-active
                                  btn-active-dark
                                  fw-bolder
                                  px-4
                                  me-1
                                " data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_2_sag">{{$t("deposit.withdrawhistory")}}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="mb-0 float-end">
                          <Datepicker v-if="depwithTab == 'deposit'" v-model="historyDate1" class="float-start ms-2"
                            :input-class="'btn btn-sm bg-dark text-light mt-3'" :format="'yyyy/MM/dd'"
                            :placeholder="$t('deposit.daterange')" :language="dil[this.$i18n.locale][0]"
                            @input="depositHistoryData()"></Datepicker>
                          <Datepicker v-if="depwithTab == 'deposit'" v-model="historyDate2"
                            class="float-start ms-2 sagtarih" :input-class="'btn btn-sm bg-dark text-light mt-3'"
                            :format="'yyyy/MM/dd'" :placeholder="$t('deposit.daterange')"
                            :language="dil[this.$i18n.locale][0]" @input="depositHistoryData()"></Datepicker>
                          <Datepicker v-if="depwithTab == 'withdraw'" v-model="historyDate1" class="float-start ms-2"
                            :input-class="'btn btn-sm bg-dark text-light mt-3'" :format="'yyyy/MM/dd'"
                            :placeholder="$t('deposit.daterange')" :language="dil[this.$i18n.locale][0]"
                            @input="withdrawHistoryData()"></Datepicker>
                          <Datepicker v-if="depwithTab == 'withdraw'" v-model="historyDate2"
                            class="float-start ms-2 sagtarih" :input-class="'btn btn-sm bg-dark text-light mt-3'"
                            :format="'yyyy/MM/dd'" :placeholder="$t('deposit.daterange')"
                            :language="dil[this.$i18n.locale][0]" @input="withdrawHistoryData()"></Datepicker>
                        </div>
                      </div>
                      <!--end::Header-->
                      <!--begin::Body-->
                      <div class="card-body py-3">
                        <div class="tab-content">
                          <!--begin::Tap pane-->
                          <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1_sag">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table class="
                                  table table-row-dashed table-row-gray-200
                                  align-middle
                                  gs-0
                                  gy-4
                                ">
                                <!--begin::Table head-->
                                <thead>
                                  <tr class="border-0">
                                    <th>{{$t("deposit.asset")}}</th>
                                    <th>{{$t("deposit.amount")}}</th>
                                    <th>{{$t("deposit.conf")}}</th>
                                    <th>{{$t("deposit.transactionid")}}</th>
                                    <th>{{$t("deposit.status")}}</th>
                                    <th>{{$t("deposit.date")}}</th>
                                  </tr>
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody>
                                  <tr v-for="(deposit, index) in depositHistory" :key="index">
                                    <td>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{
                                        deposit.dep_history_wallet_short
                                        }}</a>
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{ (walletList[deposit.dep_history_wallet_short]) ?
                                          walletList[deposit.dep_history_wallet_short]["wallet_name"] : ""}}</a>
                                      </span>
                                    </td>
                                    <td>
                                      <span>{{ (deposit.dep_history_amount) ? deposit.dep_history_amount.toFixed(8) :
                                        0}}</span>
                                      <span class="text-muted fw-bold d-block">{{(walletList[
                                        deposit.dep_history_wallet_short]) ? (deposit.dep_history_amount * walletList[
                                        deposit.dep_history_wallet_short]["wallet_price"]).toFixed(2) : 0}}
                                        USDT</span>
                                    </td>
                                    <td class="text-muted fw-bold">
                                      <span class="text-muted fw-bold d-block">{{
                                        deposit.dep_history_comfirmation
                                        }}
                                        >=
                                        {{(walletList[ deposit.dep_history_wallet_short]) ?
                                        walletList[deposit.dep_history_wallet_short]["wallet_conf"]: ""}}</span>
                                    </td>
                                    <td>
                                      <!-- <a
                                        href="#"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        "
                                        >{{
                                          deposit.dep_history_address.slice(
                                            0,
                                            15
                                          )
                                        }}...
                                        <span
                                          @click="
                                            copyInput(
                                              deposit.dep_history_address,
                                              'Address'
                                            )
                                          "
                                          class="
                                            svg-icon svg-icon-muted svg-icon-2hx
                                          "
                                          ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect
                                              opacity="0.5"
                                              x="7"
                                              y="2"
                                              width="9"
                                              height="11"
                                              rx="3"
                                              fill="black"
                                            />
                                            <rect
                                              x="3"
                                              y="5"
                                              width="9"
                                              height="11"
                                              rx="3"
                                              fill="black"
                                            /></svg
                                        ></span>
                                      </a>-->
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{
                                          deposit.dep_history_txid.slice(
                                          0,
                                          15
                                          )
                                          }}...
                                          <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen028.svg-->
                                          <span @click="
                                              copyInput(
                                                deposit.dep_history_txid,
                                                'Txid'
                                              )
                                            " class="
                                              svg-icon
                                              svg-icon-muted
                                              svg-icon-2hx
                                            "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none">
                                              <rect opacity="0.5" x="7" y="2" width="9" height="11" rx="3"
                                                fill="black" />
                                              <rect x="3" y="5" width="9" height="11" rx="3" fill="black" />
                                            </svg></span>
                                          <!--end::Svg Icon-->
                                        </a>
                                      </span>
                                    </td>
                                    <td>
                                      <div class="
                                          menu
                                          menu-column
                                          menu-title-gray-800
                                          menu-state-title-primary
                                          menu-state-icon-primary
                                          menu-state-bullet-primary
                                          menu-arrow-gray-500
                                        " data-kt-menu="true">
                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
                                          <span class="menu-link p-0">
                                            <span class="menu-icon">
                                              <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg-->
                                              <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                  viewBox="0 0 24 24" fill="none">
                                                  <path v-if="
                                                      deposit.dep_history_status ===
                                                      0
                                                    "
                                                    d="M18.0002 22H6.00019C5.20019 22 4.8002 21.1 5.2002 20.4L12.0002 12L18.8002 20.4C19.3002 21.1 18.8002 22 18.0002 22Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      deposit.dep_history_status ===
                                                      0
                                                    " opacity="0.3"
                                                    d="M18.8002 3.6L12.0002 12L5.20019 3.6C4.70019 3 5.20018 2 6.00018 2H18.0002C18.8002 2 19.3002 2.9 18.8002 3.6Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      deposit.dep_history_status ===
                                                      1
                                                    " opacity="0.3"
                                                    d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      deposit.dep_history_status ===
                                                      1
                                                    "
                                                    d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                                                    fill="black" />
                                                </svg>
                                              </span>
                                            </span>
                                            <span v-if="
                                                deposit.dep_history_status === 0
                                              ">{{$t("deposit.processing")}}</span>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {{
                                      new Date(deposit.dep_history_time * 1000).toLocaleString()
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Table body-->
                              </table>
                            </div>
                            <!--end::Table-->
                          </div>
                          <div class="tab-pane fade" id="kt_table_widget_5_tab_2_sag">
                            <!--begin::Table container-->
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <!--begin::Table-->
                              <table class="
                                  table table-row-dashed table-row-gray-200
                                  align-middle
                                  gs-0
                                  gy-4
                                ">
                                <!--begin::Table head-->
                                <thead>
                                  <tr class="border-0">
                                    <th>{{$t("deposit.asset")}}</th>
                                    <th>{{$t("deposit.amount")}}</th>
                                    <th>{{$t("deposit.address")}} - Txid</th>
                                    <th>{{$t("deposit.status")}}</th>
                                    <th>{{$t("deposit.date")}}</th>
                                  </tr>
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody>
                                  <tr v-for="(withdraw, index) in withdrawHistory" :key="index">
                                    <td>
                                      <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                        class="
                                          text-dark
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                          fs-6
                                        ">{{ withdraw.withdraw_wallet_short }}</a>
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_create_depWith"
                                          class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{(walletList[withdraw.withdraw_wallet_short]) ?
                                          walletList[withdraw.withdraw_wallet_short]["wallet_name"]: ""}}</a>
                                      </span>
                                    </td>
                                    <td>
                                      <span>{{(withdraw.withdraw_amount) ? withdraw.withdraw_amount.toFixed(8) :
                                        0}}</span>
                                      <span class="text-muted fw-bold d-block">{{(walletList[withdraw.withdraw_wallet_short]) ? (withdraw.withdraw_amount *walletList[withdraw.withdraw_wallet_short]["wallet_price"]).toFixed(2):0}}
                                        USDT</span>
                                    </td>
                                    <td>
                                      <a href="#" class="
                                          text-muted
                                          fw-bolder
                                          text-hover-primary
                                          mb-1
                                        ">{{
                                        withdraw.withdraw_address.slice(
                                        0,
                                        15
                                        )
                                        }}...
                                        <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen028.svg-->
                                        <span @click="
                                            copyInput(
                                              withdraw.withdraw_address,
                                              'Address'
                                            )
                                          " class="
                                            svg-icon svg-icon-muted svg-icon-2hx
                                          "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="7" y="2" width="9" height="11" rx="3" fill="black" />
                                            <rect x="3" y="5" width="9" height="11" rx="3" fill="black" />
                                          </svg></span>
                                        <!--end::Svg Icon-->
                                      </a>
                                      <span class="text-muted fw-bold d-block">
                                        <a href="#" class="
                                            text-muted
                                            fw-bolder
                                            text-hover-primary
                                          ">{{
                                          withdraw.withdraw_txid.slice(0, 15)
                                          }}...
                                          <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen028.svg-->
                                          <span @click="
                                              copyInput(
                                                withdraw.withdraw_txid,
                                                'Txid'
                                              )
                                            " class="
                                              svg-icon
                                              svg-icon-muted
                                              svg-icon-2hx
                                            "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                              viewBox="0 0 24 24" fill="none">
                                              <rect opacity="0.5" x="7" y="2" width="9" height="11" rx="3"
                                                fill="black" />
                                              <rect x="3" y="5" width="9" height="11" rx="3" fill="black" />
                                            </svg></span>
                                          <!--end::Svg Icon-->
                                        </a>
                                      </span>
                                    </td>
                                    <td>
                                      <div class="
                                          menu
                                          menu-column
                                          menu-title-gray-800
                                          menu-state-title-primary
                                          menu-state-icon-primary
                                          menu-state-bullet-primary
                                          menu-arrow-gray-500
                                        " data-kt-menu="true">
                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
                                          <span class="menu-link p-0">
                                            <span class="menu-icon">
                                              <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm007.svg-->
                                              <span class="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                  viewBox="0 0 24 24" fill="none">
                                                  <path v-if="
                                                      withdraw.withdraw_status ===
                                                      0
                                                    "
                                                    d="M18.0002 22H6.00019C5.20019 22 4.8002 21.1 5.2002 20.4L12.0002 12L18.8002 20.4C19.3002 21.1 18.8002 22 18.0002 22Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      withdraw.withdraw_status ===
                                                      0
                                                    " opacity="0.3"
                                                    d="M18.8002 3.6L12.0002 12L5.20019 3.6C4.70019 3 5.20018 2 6.00018 2H18.0002C18.8002 2 19.3002 2.9 18.8002 3.6Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      withdraw.withdraw_status ===
                                                      1
                                                    " opacity="0.3"
                                                    d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                                                    fill="black" />
                                                  <path v-if="
                                                      withdraw.withdraw_status ===
                                                      1
                                                    "
                                                    d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                                                    fill="black" />
                                                </svg>
                                              </span>
                                            </span>
                                            <span v-if="
                                                withdraw.withdraw_status === 0
                                              ">{{$t("deposit.processing")}}</span>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {{
                                      new Date(
                                      withdraw.withdraw_time * 1000
                                      ).toLocaleString()
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Table body-->
                              </table>
                            </div>
                            <!--end::Table-->
                          </div>
                          <!--end::Tap pane-->
                        </div>
                      </div>
                      <!--end::Body-->
                    </div>
                    <!--end::Tables Widget 5-->
                  </div>
                  <!--depositwithdraw tablo end-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Container-->
            </div>
            <!--end::Post-->
          </div>
          <!--end::Content-->
          <Footer></Footer>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::Root-->
    <!--begin::Drawers-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black" />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Scrolltop-->
    <!--end::Main-->
  </div>
</template>
<script>
  import DataService from "../services/DataService";
  import Asidemenu from "./Sidebar";
  import Header from "./shares/Header";
  import Footer from "./shares/Footer";
  import Datepicker from "vuejs-datepicker";
  import { tr, en, ru } from "vuejs-datepicker/dist/locale"; //this.$i18n.locale
  export default {
    name: "DepositWithdraw",
    metaInfo: function() {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | "+this.$t("metatag.titleDetail"),
        meta: [
        { name: 'description', content:  this.$t("metatag.description") },
        { name: 'keywords', content:  this.$t("metatag.keywords") }
        ]
      }
    },
    components: {
      Asidemenu,
      Header,
      Footer,
      Datepicker,
    },
    data() {
      return {
        walletall: {},
        dil: { tr: [tr], en: [en], ru: [ru] },
        selectWalletShort: "",
        historyDate1: new Date(Date.now() - 3600 * 1000 * 24 * 15),
        historyDate2: new Date(),
        walletList: {},
        supbalance: 0,
        borrowlimit: 0,
        isSuccess: {},
        isSuccessb: {},
        borrowbalanceUSDT: 0,
        userControl: this.$session.get("logtoken") ? 1 : 0,
        totalSupply: {},
        totalBorrow: {},
        depositHistory: {},
        withdrawHistory: {},
        depwithTab: "deposit",
      };
    },
    methods: {
      sessionKontrol() {
        if (!this.$session.get("logtoken")) {
          window.location.href = `/${this.$session.get("lang")}/login`;
        }
      },
      allWallets() {
        if (this.userControl != 1) return;
        if (this.sayfaDurum == 0) { return; }
        this.supbalance = 0;
        this.borrowlimit = 0;
        this.borrowbalanceUSDT = 0;
        DataService.getUserWallets({
          token: this.$session.get("logtoken"),
        }).then((response2) => {
          DataService.getAllWallets().then((response) => {
            this.walletall = response.data;
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              if (element) this.walletList[element.wallet_short] = element;
              this.userWallet(element.wallet_short, response2.data[element.wallet_short]);
            }
          });
        });
      },
      userWallet(gelen, userWallet) {
        if (userWallet) {
          if (userWallet.wallet_user_earn === 1) {
            this.supbalance = this.supbalance + userWallet.wallet_user_balance * this.walletList[gelen]["wallet_price"];
          }
          if (userWallet.wallet_user_coll === 1) {
            this.borrowlimit = this.borrowlimit + userWallet.wallet_user_balance *
              this.walletList[gelen]["wallet_price"] * ("0." + this.walletList[gelen].wallet_col_factor);
          }



          if (userWallet.wallet_user_borrow_balance) {
            this.walletList[gelen]["borrowbalance"] = userWallet.wallet_user_borrow_balance;
            this.$session.set(gelen + '_bbalance', this.walletList[gelen]["borrowbalance"])
            this.borrowbalanceUSDT = this.borrowbalanceUSDT + userWallet.wallet_user_borrow_balance * this.walletList[gelen]["wallet_price"];
          } else {
            this.$session.set(gelen + '_bbalance', 0)
          }

        }
      },
      veriYenile() {
        setInterval(() => {
          this.allWallets();
        }, 15000);
      },
      depositHistoryData() {
        if (this.historyDate1 && this.historyDate2) {
          DataService.depositHistory({
            token: this.$session.get("logtoken"),
            date1: this.historyDate1.getTime().toString().substring(0, 10),
            date2: this.historyDate2.getTime().toString().substring(0, 10),
          }).then((response) => {
            this.depositHistory = response.data;
            this.$forceUpdate();
          });
        }
      },
      withdrawHistoryData() {
        if (this.historyDate1 && this.historyDate2) {
          DataService.withdrawHistory({
            token: this.$session.get("logtoken"),
            date1: this.historyDate1.getTime().toString().substring(0, 10),
            date2: this.historyDate2.getTime().toString().substring(0, 10),
          }).then((response) => {
            this.withdrawHistory = response.data;
            this.$forceUpdate();
          });
        }
      },
      copyInput(input, veri) {
        navigator.clipboard.writeText(input);
        this.isCopied = true;
        this.$vToastify.setSettings({ position: "bottom-right", theme: "light" });
        this.$vToastify.success("", veri + " başarıyla kopyalandı.");
      },
    },
    computed: {
      borrowbalanceyuzde: function () {
        let kontrol = "";
        if ((this.borrowbalanceUSDT / this.borrowlimit) * 100) {
          kontrol = (this.borrowbalanceUSDT / this.borrowlimit) * 100;
        } else {
          kontrol = 0;
        }
        return kontrol;
      },
      isSuccessBorrowUst: function () {
        let kontrol = "";
        if (this.borrowbalanceyuzde <= 50) {
          kontrol = "success";
        } else if (this.borrowbalanceyuzde > 50 && this.borrowbalanceyuzde < 80) {
          kontrol = "warning";
        } else if (this.borrowbalanceyuzde > 80) {
          kontrol = "danger";
        }
        return kontrol;
      },
    },
    mounted() {
      this.sessionKontrol();
      const d = new Date();let time = d.getTime().toString().substring(0, 10);this.$session.set("sessionTimeout",parseInt(time)+3600);
    },
    created() {
      this.allWallets();
      this.depositHistoryData();
      this.withdrawHistoryData();
    },
  };
</script>